.side-progress-bar {
  width: 320px;
  background-color: #ffffff;
  height: 100% !important;
  margin-right: 170px;
}

.registrationLink {
  text-decoration: underline !important;
  color: #074B89 !important;
}

/* border change */
#registration .form-outline .form-control~.form-notch div {
  border: none;
}

.side-progress-bar .kredu-logo {
  width: 100px;
  margin-top: 15vh;
  margin-bottom: 14vh;
}

#registration .input-group>.form-control:focus {
  transition: all .2s linear;
  border-color: #074B89 !important;
  outline: 0;
  box-shadow: inset 0 0 0 1px #074B89 !important;
}
#registration .input-group>.form-control:active {
  transition: all .2s linear;
  border-color: #074B89 !important;
  outline: 0;
  box-shadow: inset 0 0 0 1px #074B89 !important;
}

#registration .select-input-label-active {
  color: #074B89 !important;
}

#registration  .form-outline .form-control:focus~.form-label {
  color: #074B89 !important;
}

#registration .select-input.focused~.select-label {
  color: #074B89 !important;
}


#registration .form-control label:active {
  border-color: #074B89;
  
}

/* .form-outline {
  border: none !important;
} */

#registration .select-input-label-active,
#cart .select-input-label-active {
  color: #074B89 !important;
}

#registration .form-outline .formControl:focus~.form-label,
#cart .form-outline .formControl:focus~.form-label {
  color: #074B89 !important;
}

#registration .select-input.focused~.select-label {
  color: #074B89 !important;
}

.datepicker-header {
  background-color: #074B89 !important;
}

.datepicker-cell.selected .datepicker-cell-content {
  background-color: #074B89 !important;
  color: #fff;
}

.form-control label:active {
  border-color: #074B89;
  
}

.form-outline:focus {
  background: #fff;
}
.form-outline:hover {
  background: #fff;  
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

#registration .form-outline .form-control:focus~.form-notch .form-notch-leading,
#cart .form-outline .form-control:focus~.form-notch .form-notch-leading {
  border-top: .125rem solid #074B89;
  border-bottom: .125rem solid #074B89;
  border-left: .125rem solid #074B89;
  background: #fff;
}
#registration .form-outline .form-control:focus~.form-notch .form-notch-middle,
#cart .form-outline .form-control:focus~.form-notch .form-notch-middle {
  border-bottom: .125rem solid;
  border-color: #155ea2;
  
}

#registration .form-outline .form-control:focus~.form-notch .form-notch-trailing,
#cart .form-outline .form-control:focus~.form-notch .form-notch-trailing {
  border-color: currentcolor currentcolor currentcolor #074B89;
  border-bottom: .125rem solid #074B89;
  border-right: .125rem solid #074B89;
  border-top: .125rem solid #074B89;
  
}

.datepicker-footer-btn {
  color: #074B89 !important;
}

.select-input.focused~.select-arrow {
  color: #074B89 !important;
}

#registration .form-outline .form-control:focus~.form-label, 
#cart .form-outline .form-control:focus~.form-label, 
#registration .form-outline .select-input.form-control:focus~.form-label {
  color: #074B89;
}		

#registration .select-input-label-active {
  color: #074B89 !important;
  border-color: #074B89 !important;
}

#registration .select-input:active {
  color: #074B89 !important;
  border-color: #074B89 !important;
}

#registration .select-input-label:focus {
  color: #074B89 !important;
  border-color: #074B89 !important;
}

#registration .form-check-input {
  border-radius: 100px;
  border-color: #074B89;
}

#registration .form-check-input:checked {
  border-color: #074B89 !important;
}

.form-check-input[type=checkbox]:checked {
  background-image: none;
  background-color: #074B89;
}

.form-check-input[type=checkbox]:focus{
  background-image: none;
  background-color: #074B89;
}

.form-control:focus {
  color: #074B89 !important;
  border-color: #074B89 !important;
  box-shadow: inset 0px 0px 0px 1px #074B89 ;
}

.form-control .form-label:active {
  color: #074B89 !important;
  border-color: #074B89 !important;
}

.form-control .form-label:focus {
  color: #074B89 !important;
  border-color: #074B89 !important;
}


.form-check-input[type=checkbox] {
  border-radius: 100px;
  margin-top: .19em;
  margin-right: 8px;
}

.additional-documents .input-group-text {
  border: none;

}

.registration-form-box .additional-documents .form-control,
.registration-form-box-2 .additional-documents .form-control {
  background: inherit;
  border: none;
  padding: 8px;
  color: #074B89;
  margin-bottom: 1.5rem;
}

.document-row {
  border-bottom: solid 1px #E1E1E1;
}

.document-row p {
  font-size: 0.87rem;
  font-weight: normal;
  margin-bottom: 0 !important;
}

.order-successful p {
  text-align: center !important;
}








