/* Navigation Default*/
.navbarDefault a {
  color: #003775 !important;
}

.fontBlue {
  color: #074B89;
}

.navbarDefault {
  background: #FFFFFF !important;
  text-transform: none;
  height: 70px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}


.btnYellow {
  background: #F5B640;
  border-radius: 6px;
  color: #1f1f1f;
  font-size: 1rem;
  line-height: 140%;
  font-weight: 700;
  text-transform: none;
  padding: 12px 30px;
}

.btnYellow:hover {
  background-color: #f4a514 !important;
  border-color: none;
  color: #1f1f1f !important;
}

.btnYellow:focus {
  background-color: #f4a514 !important;
  color: #1f1f1f !important;
}

.navbarDefault {
  background: #FFFFFF;
  text-transform: none;
  height: 70px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.badge {
  background-color: #074B89;
  position: absolute;
  font-size: .6rem;
  line-height: 150%;
  font-weight: 600;
  margin-top: -0.1rem;
  margin-left: -0.5rem;
  padding: 0.1em 0.57em;
  border-radius: 50rem;
  color: #fff;
}

.cartImg {
  height: 24px;
}


#navbarDashboard .navbar-nav a {
  color: #074B89;
}

.navbar-light .navbar-toggler {
  color: #1f1f1f;
  border: none;
}


.navbar .btnYellow,
.navbar .btn-secondary-blue {
  width: 170px;
}

.main-screen {
  min-height: 100vh;
  padding-top: 62px;
}

/* Nabar Main*/
.navbarDefault {
  background: #fff;
  
}
.navbarDefault .kreduLogo {
  width: 93px;
  margin-left: 4%;
}

.btn-begin-process {
  margin: 0;
  display: none;
}


#navbarSupportedContent {
  text-align: center;
}

.navbarDefault .navbar-nav {
  align-items: center;
}


.badge {
  background-color: #074B89;
  position: absolute;
  font-size: .6rem;
  line-height: 150%;
  font-weight: 600;
  margin-top: -0.1rem;
  margin-left: -0.5rem;
  padding: 0.1em 0.57em;
  border-radius: 50rem;
  color: #fff;
}

.cartImg {
  height: 24px;
}

/* Navbar Hamburger Menu Icon 1 */

.animated-icon1 {
  width: 25px;
  height: 20px;
  position: relative;
  margin: 0px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

  .animated-icon1 span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
  }

  .animated-icon1 span {
      background: #1f1f1f;
  }

  .animated-icon1 span:nth-child(1) {
      top: 0px;
  }

  .animated-icon1 span:nth-child(2) {
      top: 8px;
  }

  .animated-icon1 span:nth-child(3) {
      top: 16px;
  }

  .animated-icon1.open span:nth-child(1) {
      top: 11px;
      -webkit-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      transform: rotate(135deg);
  }

  .animated-icon1.open span:nth-child(2) {
      opacity: 0;
      left: -60px;
  }

  .animated-icon1.open span:nth-child(3) {
      top: 11px;
      -webkit-transform: rotate(-135deg);
      -moz-transform: rotate(-135deg);
      -o-transform: rotate(-135deg);
      transform: rotate(-135deg);
  }

  @media only screen and (max-width: 1199px) {
    .btnYellow {
      width: fit-content;
      align-self: center;
    }

    .navbarDefault li {
      border-bottom: 1px solid #c6c6c6;
      padding: 12px 0;
      width: 100%;
    }

    .navbarComp.navbar-collapse {
      background: white;
      transition: 0.4s;
      box-shadow: none !important;
    }
  }

  @media only screen and (max-width: 575px) {
    .kreduLogo {
      width: 80px !important;
      margin-left: 4%;
    }
  }
  