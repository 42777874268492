.registrationFormBox {
  margin-top: 30vh;
  padding-bottom: 40px;
  min-width: 400px;
  max-width: 410px;
  animation: fadeInAnimation ease 1s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.registrationFormBox h2,
.registrationFormBox2 h2 {
  font-weight: bold;
  line-height: 140%;
}

@media screen and (max-width: 767px) {
  .registrationFormBox {
    margin-top: 0 !important;
    padding-top: 50px;
    min-width: 0;
  }

}
