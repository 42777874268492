.loading {
  margin-top: 20vh;
  margin-bottom: 15vh;
}

@media only screen and (max-width: 575px) {
  .vendorInfo h2 {
    font-size: 1.87rem !important;
  }

  .loading {
    margin-top: 10vh;
    margin-bottom: 15vh;
  }
}