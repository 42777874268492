.loading {
  margin-top: 20vh;
  padding-bottom: 5vh;
}

@media only screen and (max-width: 575px) {
  .loading {
    margin-top: 6vh;
    padding-bottom: 6vh;
  }
}