.card {
  padding-top: 5%;
  padding-bottom: 5%;
}

.icon {
  width: 64px;
}

.text {
  max-width: 412px;
}

.link {
  color: #074B89;
  font-weight: 700 !important;
  font-size: 1rem !important;
}