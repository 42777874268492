.btnVoucher {
  padding: 4px 8px;
  background: #F3F3F3;
  color: #1f1f1f;
  font-size: 0.75rem;
  line-height: 170%;
  border: none;
  border-radius: 4px;
  outline: none;
  transition:none;
  box-shadow: none;
}

.btnVoucher:hover {
  background-color: #F3F3F3 !important;
  color: #1f1f1f;
}

.btnVoucher:focus {
  background-color: #F3F3F3  !important;
  color: #1f1f1f;
  
}

.btnVoucher:not(:disabled):not(.disabled):active {
  background: #FFFFFF;
  border-color: #FFFFFF;
}