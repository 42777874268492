.marginTop {
  margin-top: 20vh;
}

.errorBox {
  max-width: 500px;
}

.errorBox p {
  font-size: 1.1rem;
}

.link {
  text-decoration: underline;
  color: #074b89;
  margin-left: 10px;
  margin-right: 10px;
  font-weight: bold;
  font-size: 0.9rem;
}

@media only screen and (max-width: 767px) {
  .marginTop {
    margin-top: 10vh;
  }
}