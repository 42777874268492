.infoBox {
  background-color: #D1EEF0;
  color: #074b89;
  border-radius: 5px;
}

@media only screen and (max-width: 575px) {
  .infoBox {
    font-size: 0.9rem;
  }
}