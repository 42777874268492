.shoppingCart {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

.shoppingCart img {
  width: 130px;
}

.shoppingCart p {
  color: #1f1f1f;
}

.shoppingCart input {
  width: 60px;
  border: none;
  text-align: center;
  font-size: 2.6rem;
  font-weight: bold;
  color: #1f1f1f;
  padding-bottom: 0;
}

.shoppingCart .addBtn {
  background: #F3F3F3;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  border: none;
  width: 30px;
  
}

.shoppingCart h3 {
  font-size: 33px;
  line-height: 150%;
  font-size: 2rem;
  letter-spacing: 0ch;
}

.shoppingCart-2 {
  border: none;
  border-radius: 6px;
}

.shoppingCart-2 .fa-check-circle {
  font-size: 1.35rem;
}

.buttons-container {
  display: block;
  background-color: inherit !important;
}


.shoppingCart-total .btn-yellow {
  padding: 12px 32px;
}



.shoppingCart-total-loggedIn .btn-yellow,
.shoppingCart-total-loggedIn .btn-klarna {
  width: 343px;
}

@media only screen and (max-width: 575px) {

  .shoppingCart-total {
    background: #ffffff;
  }

  .shoppingCart input {
    font-size: 2rem !important;
    padding-left: 10px;
  }

  .shoppingCart p,
  .shoppingCart-2 p {
    font-size: 0.85rem;
  }

  .shoppingCart-total-loggedIn .buttons-container {
    width: 100%;
  }

  .shoppingCart-total-loggedIn .btn-yellow,
  .shoppingCart-total-loggedIn .btn-klarna {
    width: 100%;
  }
}

@media only screen and (max-width: 338px) {
  #shoppingCartComponent .btn-yellow {
    font-size: 0.9rem;
    padding: 13px 6px;
  }
  .shoppingCart p,
  .shoppingCart-2 p {
    font-size: 0.75rem;
  }

  .shoppingCart-total .btn-yellow {
    padding: 12px 30px;
  }
}





