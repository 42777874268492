.purchaseHistory {
  height: 100vh;
}

.purchaseHistory .dashboardCard{
  padding: 70px 100px;
}
.bg-gradient {
  background: linear-gradient(90deg, #C4E6F3 2.47%, #C4D1F3 71.94%) !important;
  Width: 1054px;
  Height: 385px;
  border-radius: 8px;
}

.purchaseHistory .form-control:focus,
#invoicesScreen .form-control:focus, 
#dashboardInfoEdit .form-control:focus {
  color: #757575 !important;
  border-color: none !important;
  box-shadow: none !important;
}

.purchaseHistory .form-control .form-label:active,
#invoicesScreen .form-control .form-label:active,
#dashboardInfoEdit .form-control .form-label:active {
  color: #757575 !important;
  border-color: none !important;
}

.purchaseHistory .form-control .form-label:focus,
#invoicesScreen .form-control .form-label:focus,
#dashboardInfoEdit .form-control .form-label:focus {
  color: #757575 !important;
  border-color: none !important;
}

.purchaseHistory .form-control label:active,
#invoicesScreen .form-control label:active,
#dashboardInfoEdit .form-control label:active {
  border-color: none !important; 
}

.purchaseHistory .form-outline .form-control:focus~.form-notch .form-notch-leading,
#invoicesScreen .form-outline .form-control:focus~.form-notch .form-notch-leading,
#dashboardInfoEdit .form-outline .form-control:focus~.form-notch .form-notch-leading {
  border-top: none;
  border-bottom: none;
  border-left: none;
  background: #F3F3F3;
}
.purchaseHistory .form-outline .form-control:focus~.form-notch .form-notch-middle,
#invoicesScreen .form-outline .form-control:focus~.form-notch .form-notch-middle,
#dashboardInfoEdit .form-outline .form-control:focus~.form-notch .form-notch-middle {
  border-bottom: none !important;
  border-color: none !important;
  
}

.purchaseHistory .form-outline .form-control:focus~.form-notch .form-notch-trailing,
#invoicesScreen .form-outline .form-control:focus~.form-notch .form-notch-trailing,
#dashboardInfoEdit .form-outline .form-control:focus~.form-notch .form-notch-trailing {
  border-color: none !important;
  border-bottom: none !important;
  border-right: none !important;
  border-top: none !important;
  
}

.purchaseHistory .form-outline .form-control~.form-notch div,
#invoicesScreen .form-outline .form-control~.form-notch div {
  border: none;
}

.info-edit-box .form-outline .form-control~.form-notch div {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #E1E1E1;
}

.purchaseHistory .form-outline .form-control:focus~.form-label, 
.purchaseHistory .form-outline .select-input.form-control:focus~.form-label,
#invoicesScreen .form-outline .form-control:focus~.form-label, 
#invoicesScreen .form-outline .select-input.form-control:focus~.form-label,
#dashboardInfoEdit .form-outline .form-control:focus~.form-label, 
#dashboardInfoEdit .form-outline .select-input.form-control:focus~.form-label {
  color: #757575;
}	

.payment-box {
  border-bottom: solid 1px #E1E1E1;
}

#invoicesScreen .img-credit-payed {
  width: 67px;
  height: 67px;
}

@media only screen and (max-width: 1199px) {
  .purchaseHistory .bg-gradient {
    background-color: #f3f3f3;
    width: auto !important;
    height: auto !important;
  }

  .purchaseHistory .dashboardCard {
    padding: 50px !important;
  }
}

@media screen and (max-width: 991px) {
  .purchaseHistory {
    height: fit-content;
  }

  .purchaseHistory .dashboardCard {
    background-color: #f3f3f3 !important;
    background-clip: none !important;
    border: none !important;
    box-shadow: none !important;
  }
}

@media only screen and (max-width: 575px) {
  .purchaseHistory .dashboardCard {
    padding: 15px !important;
  }
}



