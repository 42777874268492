.registrationFormBox {
  margin-top: 30vh;
  padding-bottom: 40px;
  min-width: 400px;
  max-width: 450px;
  animation: fadeInAnimation ease 1s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.registrationLink {
  color: #074b89 !important;
  text-decoration: underline;
}

@media screen and (max-width: 991px) {
  .registrationFormBox {
    min-width: 0 !important;
  }
}

@media screen and (max-width: 767px) {
  .registrationFormBox {
    margin-top: 0 !important;
    padding-top: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .registrationFormBox {
    padding-top: 10px;
    width: 320px;
    margin-left: auto;
    margin-right: auto;
  }

.registrationFormBox .formControl {
  background: #fff;
  padding: 8px;
  color: #1f1f1f !important;
  
  border: none !important;
}
}