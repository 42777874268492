.contact {
  background-image: url('../../assets/img/ellipse-left.svg'), url('../../assets/img/ellipse-right.svg');
  background-repeat: no-repeat, no-repeat;
  background-position: right 0 top -120px, left 0 bottom -400px;
  background-size: 20%, 20%;
  padding-bottom: 60px;
  width: 100%;
}

.contact h1 {
  padding-top: 90px;
  padding-bottom: 50px;
}

.contact h3 {
  padding-top: 20px;
  padding-bottom: 10px;
}

.contact p {
  padding: 15px 0 25 0;
}

.card p {
  margin: 0;
  padding: 40px 0;
}

 .card {
  max-width: 700px;
  margin-top: 25vh;
  padding: 30px;
  box-shadow: 1px 3px 4px 1px rgba(0, 0, 0, 0.2);
}

@media only screen and (min-width: 1200px) {
  .contact {
      background-position: right 0 top -150px, left 0 top 100px;
      background-size: 25%, 22%;
  }
}

@media only screen and (min-width: 1600px) {
  .contact {
      background-position: right 0 top 0, left 0 top 30px;
      background-size: 300px;
  }
}

@media only screen and (min-width: 1920px) {
  .contact {
      background-position: right 0 top 40, left 0 top 50;
      background-size: 15%, 15%;
  }
}

@media only screen and (max-width: 1200px) {
.contact {
  background-position: right 0 top -120px, left 0 bottom -200px;
  background-size: 25%, 20%;
  }
}

@media only screen and (max-width: 767px) {
.card {
  max-width: 95%;
  margin-top: 20vh;
  padding: 20px;
}

.contact {
  background-position: right 0 top 0, left 0 bottom 0;
  background-size: 25%, 20%;
  }
}
