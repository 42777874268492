.registrationFormBox {
  margin-top: 30vh;
  padding-bottom: 40px;
  min-width: 400px;
  max-width: 410px;
  animation: fadeInAnimation ease 1s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}


.registrationFormBox h2 {
  font-weight: bold;
  line-height: 140%;
}

.registrationFormBox p, 
.registrationFormBox-2 p {
  font-weight: bold;
  padding-right: 10px;
}

.registrationFormBox .formControl,
.registrationFormBox-2 .formControl {
  background: #fff;
  padding: 8px;
  color: #1f1f1f !important;
  margin-bottom: 1.5rem;
  border: none;
  width: 85%;
}


.registrationFormBox .input-group>.formControl:focus, 
.registrationFormBox-2 .input-group>.formControl:focus {
  transition: all .2s linear;
  border-color: #074B89 !important;
  outline: 0;
  box-shadow: inset 0 0 0 1px #074B89 !important;
}

.registrationFormBox .select-input.formControl[readonly]:not([disabled]) {
  background-color: #fff;
}

.registrationFormBox .form-outline .formControl~.form-notch div,
.registrationFormBox-2 .form-outline .formControl~.form-notch div {
  border: none;
}

.registrationFormBox .datepicker-header {
  height: 120px;
  padding-right: 24px;
  padding-left: 24px;
  background-color: #074B89 !important;
  display: flex;
  flex-direction: column;
  border-radius: .5rem .5rem 0 0;
}

.registrationFormBox .otp-numbers {
  width: 70%;
}

.registrationFormBox .otp-numbers input {
  width: 20px !important;
  height: 50px !important;
  background: #fff;
  font-weight: bold;
  font-size: 1.6rem;
}


.registrationFormBox .otp-input:focus {
  border-color: #074B89;
  border-radius: 0;
}

.registrationFormBox-2 .alert {
  font-size: 0.85rem;
}

.registrationFormBox .form-outline {
  border: none !important;
}

.registrationFormBox .select-input-label-active {
  color: #074B89 !important;
}

.registrationFormBox .form-outline .formControl:focus~.form-label {
  color: #074B89 !important;
}

.select-input.focused~.select-label {
  color: #074B89 !important;
}

.datepicker-header {
  background-color: #074B89 !important;
}

.datepicker-cell.selected .datepicker-cell-content {
  background-color: #074B89 !important;
  color: #fff;
}

.formControl label:active {
  border-color: #074B89;
  
}

.form-outline:focus {
  background: #fff;
}
.form-outline:hover {
  background: #fff;  
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.form-outline .formControl:focus~.form-notch .form-notch-leading {
  border-top: .125rem solid #074B89;
  border-bottom: .125rem solid #074B89;
  border-left: .125rem solid #074B89;
  background: #fff;
}
.form-outline .formControl:focus~.form-notch .form-notch-middle {
  border-bottom: .125rem solid;
  border-color: #155ea2;
  
}

.form-outline .formControl:focus~.form-notch .form-notch-trailing {
  border-color: currentcolor currentcolor currentcolor #074B89;
  border-bottom: .125rem solid #074B89;
  border-right: .125rem solid #074B89;
  border-top: .125rem solid #074B89;
  
}

.form-outline .formControl:focus~.form-label, 
.form-outline .select-input.formControl:focus~.form-label {
  color: #074B89;
}		

.select-input-label-active {
  color: #074B89 !important;
  border-color: #074B89 !important;
}

.select-input:active {
  color: #074B89 !important;
  border-color: #074B89 !important;
}

.select-input-label:focus {
  color: #074B89 !important;
  border-color: #074B89 !important;
}

.form-check-input {
  border-radius: 100px;
  border-color: #074B89;
}

.form-check-input:checked {
  border-color: #074B89 !important;
}

.form-check-input[type=checkbox]:checked {
  background-image: none;
  background-color: #074B89;
}

.form-check-input[type=checkbox]:focus{
  background-image: none;
  background-color: #074B89;
}

.formControl:focus {
  color: #074B89 !important;
  border-color: #074B89 !important;
  box-shadow: inset 0px 0px 0px 1px #074B89 ;
}

.formControl .form-label:active {
  color: #074B89 !important;
  border-color: #074B89 !important;
}

.formControl .form-label:focus {
  color: #074B89 !important;
  border-color: #074B89 !important;
}


.form-check-input[type=checkbox] {
  border-radius: 100px;
  margin-top: .19em;
  margin-right: 8px;
}

.additional-documents .input-group-text {
  border: none;

}

.registration-formBox .additional-documents .formControl,
.registration-formBox-2 .additional-documents .formControl {
  background: inherit;
  border: none;
  padding: 8px;
  color: #074B89;
  margin-bottom: 1.5rem;
}

.document-row {
  border-bottom: solid 1px #E1E1E1;
}

.document-row p {
  font-size: 0.87rem;
  font-weight: normal;
  margin-bottom: 0 !important;
}

.order-successful p {
  text-align: center !important;
}


@media screen and (max-width: 991px) {
  .registrationFormBox {
    min-width: 0 !important;
  }
  .registrationFormBox-2 {
    min-width: 0 !important;
  }
}

@media screen and (max-width: 767px) {
  .registrationFormBox,
  .registrationFormBox-2 {
    margin-top: 0 !important;
  }

  .registrationFormBox .p-registration,
  .registrationFormBox-2 .p-registration {
    font-weight: 400;
  }

  .registrationFormBox .btnYellow,
  .registrationFormBox-2 .btnYellow {
    width: 100% !important;
  }
}

@media only screen and (max-width: 575px) {
.registrationFormBox .formControl,
.registrationFormBox-2 .formControl {
  background: #fff;
  padding: 8px;
  color: #1f1f1f !important;
  margin-bottom: 1.5rem;
  border: none;
  width: 80%;
}
}