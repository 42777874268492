.checkout {
  padding: 120px 0;
}

.voucher {
  width: 140px;
}

.spinnerContainer {
  margin-top: 40vh;
}

.total {
  font-size: 1.6rem;
  font-weight: 700;
}

.vatBox {
  font-size: 0.9rem;
}

.number {
  font-size: 1.8rem;
}

.link {
  width: fit-content;
  color: #074b89;
}

.checkbox {
  font-size: 0.9rem; 
}

.form input {
  float: none !important;
  display: inline-block !important;
}

.form label {
  display: inline !important;
}

.infoBox {
  margin: 25px 0 0 0;
  padding: 0;
}

.fontBlue {
  color: #074B89;
}

 @media only screen and (max-width: 991px) {
  .small {
    font-size: 0.85rem;
   }

   .infoBox {
    margin: 20px 0 0 0;
  }
 }

@media only screen and (max-width: 575px) {
  .checkout {
    padding: 90px 10px 30px 10px;
  }
  .voucher {
    width: 140px;
  }

  .btnYellow {
    height: 45px;
    width: 100%;
    padding: 10px 15px !important;
  }

  .card p {
    font-size: 0.95rem;
  }

  .infoBox {
    margin: 15px 0 0 0;
  }

  
}