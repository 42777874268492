.faq .fontBlue {
  color: #074b89 !important;
  text-decoration: underline;
}

.faq .accordion-button:not(.collapsed) {  
    color: #1F1F1F !important;
}
.faq .accordion-button:focus {  
    color: #1F1F1F !important;
}

.faq .accordion-button::after {
  color: #074b89 !important;
}

.faq .accordion-header:not(.collapsed) {
  color: #1F1F1F !important;
}
.faq .accordion-header:focus {
  color: #1F1F1F !important;
}

.faq .card {
  border-top: #B5B5B5 solid 1px;
  border-bottom: #B5B5B5 solid 1px;
  border-left: 0;
  border-right: 0;
  background-color: #f3f3f3;
}

.faq a {
  color: #1F1F1F;
  display: flex;
}

.faqItem {
  margin-bottom: 10px;
}

.faqItem h2 {
  line-height: 100%;
  color: #074b89 !important;
}

.faq  i {
  justify-items: left;
  margin-left: auto;
  color: #19A8B4;
}

.faq .card-body {
  padding: 10px 18px 18px 0;
}

.faq .card-body a {
  color: #19A8B4; 
}

@media only screen and (max-width: 495px) {
  .faq h3 {
    font-size: 1.1rem !important;
  }
}