.btnYellow:hover {
  background-color: #f4a514 !important;
  border-color: none;
  color: #1f1f1f !important;
}

.btnYellow:focus {
  background-color: #f4a514 !important;
  color: #1f1f1f !important;
}

.detailsLink {
  margin-top: -10px !important;
  font-size: 0.8rem;
}

.outOfStockBtn {
  width:20em;
  background-color: #d8d8d8 !important;
  color: #1F1F1F !important;
  font-weight: bold;
}

.cardsSection {
  padding: 60px 0;
}

.btnYellow {
  background-color: #f4a514e3;
  color: #1f1f1f;  
  font-size: .9rem !important;
  line-height: 1.3rem;
  text-transform: none;  
  font-weight: 600;  
  height: 2.8em;
  margin-top: -5px;
  width:17em;
}


.cardBox {
  margin-bottom: 30px;
  padding: 20px 10px;
  width: 300px;
  height: 310px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;

}

.cardControls a{
  color: #074b89 !important;
}

.numberInput input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.numberInput input[type=number]::-webkit-inner-spin-button,
.numberInput input[type=number]::-webkit-outer-spin-button {
  --webkit-appearance: none;
}

.numberInput .minus,
.numberInput .plus{
  --webkit-appearance: none;
  border: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  background: #F3F3F3;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 6px;    
  
}

.cardControls {  
  text-align: center;
  padding-left: 5px;
  margin-top: -5px;
}



.numberInput .quantity {
  padding: 3px;
}

.numberInput button:before,
.numberInput button:after {
  display: inline-block;
  position: absolute;
  content: '';
  height: 2px;
  transform: translate(-50%, -50%);
}

.numberInput button.plus:after {
  transform: translate(-50%, -50%) rotate(90deg);
}

.numberInput input[type=number] {
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
}

.numberInput {
  border: none;
  width: 6rem;
  border-radius: .25rem;
}

.numberInput.numberInput button {
  width: 32px;
  height: 32px;
}

.numberInput.numberInput button:focus {
  border: none;
}

.numberInput.numberInput button.minus {
  padding-left: 10px;
}

.numberInput.numberInput button:before,
.numberInput.numberInput button:after {
  width: .8rem;
  background-color: #495057;
}

.numberInput.numberInput input[type=number] {
  max-width: 2rem;
  padding: 0;
  border: 0;
  font-size: 1rem;
  height: 46px;
  background: none;
  color: #1F1F1F;
}

@media screen and (max-width: 991px) {
  .cardBox {
    padding-bottom: 20px !important;
  }
}


@media only screen and (max-width: 495px) {
  .cardBox img {
    width: 100%;
  }
}


