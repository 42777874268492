.badge {
  background-color: #074B89;
  position: absolute;
  font-size: .6rem;
  line-height: 150%;
  font-weight: 600;
  margin-top: -0.1rem;
  margin-left: -0.5rem;
  padding: 0.1em 0.57em;
  border-radius: 50rem;
  color: #fff;
}

.cartImg {
  height: 35px;
}

.fontBlue {
  color: #074B89;
}