.navbarDashboard .navbar-collapse, 
.navbarDashboardOnly .navbar-collapse {
  background: #FFFFFF;
  width: 100vw;
  min-height: 100vh;
  text-align: center;
}

.fontBlue {
  color: #074B89;
}

.navbarDashboard li, 
.navbarDashboardOnly li
 {
  border-bottom: 1px solid #c6c6c6;
  padding: 12px 0;
  width: 100%;
}

.navbarDashboard .navbar {
  background: #FFFFFF;
  text-transform: none;
  height: 70px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.navLink {
  color: #074B89 !important;
}

.navbarDashboard a {
  color: #074B89 !important;
}

.navbarDashboard .navbar-nav {
  align-items: center;
}

.btnYellow {
  background: #F5B640;
  border-radius: 6px;
  color: #1f1f1f;
  font-size: 1rem;
  line-height: 140%;
  font-weight: 700;
  text-transform: none;
  padding: 12px 30px;
}

.btnYellow:hover {
  background-color: #f4a514 !important;
  border-color: none;
  color: #1f1f1f !important;
}

.btnYellow:focus {
  background-color: #f4a514 !important;
  color: #1f1f1f !important;
}

.badge {
  background-color: #074B89;
  position: absolute;
  font-size: .6rem;
  line-height: 150%;
  font-weight: 600;
  margin-top: -0.1rem;
  margin-left: -0.5rem;
  padding: 0.1em 0.57em;
  border-radius: 50rem;
  color: #fff;
}

.cartImg {
  height: 24px;
}

.kreduLogo {
  width: 93px;
  margin-left: 4%;
}

.navbar {
  background: #FFFFFF;
  text-transform: none;
  height: 70px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.navbar-collapse, 
.navbar-collapse {
  background: #FFFFFF;
  width: 100vw;
  min-height: 100vh;
  text-align: center;
}

@media only screen and (max-width: 1199px) {
  .navbar-collapse {
    background: #ffffff;
    width: 100vw;
    min-height: 100vh;
    text-align: center;
  }

  .navbarDashboard li,
  .navbarDashboardOnly li,
  .navbarHomepage li {
    border-bottom: 1px solid #c6c6c6;
    padding: 12px 0;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {

.dashboardNavMobile .dash-nav {
  font-size: 0.69rem;
}

.dashboardNavMobile a {
  color: #1f1f1f;
}
}

@media only screen and (max-width: 575px) {
.kreduLogo {
  width: 80px !important;
  margin-left: 4%;
}
}