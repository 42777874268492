.registrationFormBox2 {
  margin-top: 30vh;
  padding-bottom: 40px;
  min-width: 400px;
  max-width: 500px;
  animation: fadeInAnimation ease 1s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.registrationFormBox2 h2 {
  font-weight: bold;
  line-height: 140%;
}

.registrationFormBox2 p {
  font-weight: bold;
  padding-right: 10px;
}

.registrationFormBox2 input[type="file"]::file-selector-button {
  margin-right: 10px;
  color: #074b89;
  border: 1px solid #074b89;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  /* background-image: url('../../assets/img/upload-file-icon.svg');
  background-repeat: no-repeat; */
  
}

.spinner {
  color: #074b89;
}

.fileName {
  max-width: 300px;
  word-wrap: break-word;
}

.documentRow {
  border-bottom: solid 1px #E1E1E1;
}

.statusRejected {
  color: #e31452;
}

.statusInProgress {
  color: #f79e1b;
}

.statusAccepted {
  color: #15a11c;
}

.btnYellow:disabled {
  background: #f5b640;
}


.registrationFormBox2 .alert {
  font-size: 0.85rem;
}

.registrationFormBox .select-input-label-active {
  color: #074b89 !important;
}

.registrationFormBox .form-outline .formControl:focus ~ .form-label {
  color: #074b89 !important;
}

.select-input.focused ~ .select-label {
  color: #074b89 !important;
}

.formControl:focus {
  color: #074b89 !important;
  border-color: #074b89 !important;
  box-shadow: inset 0px 0px 0px 1px #074b89;
}

.formControl .form-label:active {
  color: #074b89 !important;
  border-color: #074b89 !important;
}

.formControl .form-label:focus {
  color: #074b89 !important;
  border-color: #074b89 !important;
}

.form-check-input[type='checkbox'] {
  border-radius: 100px;
  margin-top: 0.19em;
  margin-right: 8px;
}

.registrationFormBox2 .additionalDocuments .formControl {
  background: inherit;
  border: none;
  padding: 8px;
  color: #074b89;
  margin-bottom: 1.5rem;
}

.document-row p {
  font-size: 0.87rem;
  font-weight: normal;
  margin-bottom: 0 !important;
}

.order-successful p {
  text-align: center !important;
}

.documentSelect {
  background-color: #fff;
}

@media screen and (max-width: 991px) {
  .registrationFormBox2 {
    min-width: 0 !important;
  }
}

@media screen and (max-width: 767px) {
  .registrationFormBox2 {
    margin-top: 0 !important;
    padding-top: 50px;
  }

  .registrationFormBox2 .pRegistration {
    font-weight: 400;
  }

  .registrationFormBox2 .btnYellow {
    width: 100% !important;
  }
}

@media only screen and (max-width: 575px) {
  .registrationFormBox2 .formControl {
    background: #fff;
    padding: 8px;
    color: #1f1f1f !important;
    margin-bottom: 1.5rem;
    border: none;
  }
}
