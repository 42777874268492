/* Main Footer */
.mainFooter {
  padding: 48px 0;
}

.mainFooter p,
.mainFooter a {
  font-size: 1rem;
}

.mainFooter .mainFooterBox {
  padding: 48px 24px;
}

.mainFooter a {
  display: block;
  color: #1f1f1f !important;
}

.mainFooter .mainFooterRow {
  border-top: 1px solid #1f1f1f;
}

.mainFooter p {
  margin-block-start: 0;
}

.mainFooter .socialMedia {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 991px) {
  .mainFooter p,
  .mainFooter a {
    font-size: 0.87rem !important;
  }
}

@media screen and (max-width: 767px) {
  .mainFooter .mainFooterBox {
    padding: 24px !important;
  }
}

@media only screen and (max-width: 575px) {
  .mainFooter {
    padding: 16px 0 !important;
  }

  .mainFooter .socialMedia {
    order: 1 !important;
    justify-content: center !important;
  }

  .mainFooter .copyrightBox {
    order: 2 !important;
    text-align: center !important;
  }

  .mainFooter .logoBox {
    order: 3 !important;
  }
}
