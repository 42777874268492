.btnBlue {
  width: fit-content;
  text-decoration: underline;
  color: #074b89 !important;
  font-size: 1rem;
}

.scrollBox {
  min-height: 500px;
}

.spinner {
  color: #757575;
  width: 8px;
  height: 8px;
}

@media only screen and (max-width: 575px) {
  
}

