.bgGradient {
  background: linear-gradient(90deg, #c4e6f3 2.47%, #c4d1f3 71.94%) !important;
  width: 1054px;
  height: 385px ;
  border-radius: 8px;
}


.voucher {
  max-width: 420px;
}

.dashboardCard .form-control:focus {
  color: #757575 !important;
  border-color: none !important;
  box-shadow: none !important;
}

.dashboardCard .form-control .form-label:active {
  color: #757575 !important;
  border-color: none !important;
}

.dashboardCard .form-control .form-label:focus {
  color: #757575 !important;
  border-color: none !important;
}

.dashboardCard .form-control label:active {
  border-color: none !important; 
}

.dashboardCard .form-outline .form-control:focus~.form-notch .form-notch-leading {
  border-top: none;
  border-bottom: none;
  border-left: none;
  background: #F3F3F3;
}
.dashboardCard .form-outline .form-control:focus~.form-notch .form-notch-middle {
  border-bottom: none !important;
  border-color: none !important;
  
}

 .form-outline .form-control:focus~.form-notch .form-notch-trailing {
  border-color: none !important;
  border-bottom: none !important;
  border-right: none !important;
  border-top: none !important;
  
}

.purchaseDate {
  border-color: none !important;
  border-bottom: none !important;
  border-right: none !important;
  border-top: none !important;
}

.dashboardCard .form-outline .form-control~.form-notch div {
  border: none;
}

.info-edit-box .form-outline .form-control~.form-notch div {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #E1E1E1;
}

.dashboardCard .form-outline .form-control:focus~.form-label, 
.dashboardCard .form-outline .select-input.form-control:focus~.form-label {
  color: #757575;
}	

.paymentBox {
  border-bottom: solid 1px #E1E1E1;
}

 .img-credit-payed {
  width: 67px;
  height: 67px;
}

.small {
  font-size: 0.8rem;
}

.mediumFont {
  font-size: 1.1rem;
}

.spinnerContainer {
  margin-top: 40vh;
}

@media only screen and (max-width: 1199px) {
  .bgGradient {
    background-color: #f3f3f3;
    width: auto !important;
    height: auto !important;
  }

  .purchaseHistory .dashboard-card {
    padding: 50px !important;
  }

  #invoicesScreen .dashboard-card {
    padding: 50px 30px !important;
  }

  .purchaseHistory .dashboard-card {
    padding: 50px !important;
  }

  .dashboardCard {
    padding: 50px !important;
  }
}

@media screen and (max-width: 991px) {
  .purchaseHistory,
  #navbarDashboardOnly {
    height: fit-content;
  }

  .purchaseHistory .dashboard-card {
    background-color: #f3f3f3 !important;
    background-clip: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  .dashboardCard {
    background-color: #f3f3f3 !important;
    background-clip: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  .spinnerContainer {
    margin-top: 20vh;
  }
}

@media screen and (max-width: 575px) {
  .purchaseHistory .dashboard-card {
    padding: 15px !important;
  }

  .dashboardCard {
    padding: 15px !important;
  }
}
