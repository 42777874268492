.bgGradient {
  background: linear-gradient(90deg, #c4e6f3 2.47%, #c4d1f3 71.94%);
  width: 1054px;
  height: 385px ;
  border-radius: 8px;
}

.dashboardCard {
  padding: 70px 100px 70px 80px;
}

.dashboardCard .form-control:focus {
  color: #757575 !important;
  border-color: none !important;
  box-shadow: none !important;
}

.dashboardCard .form-control .form-label:active {
  color: #757575 !important;
  border-color: none !important;
}

.dashboardCard .form-control .form-label:focus {
  color: #757575 !important;
  border-color: none !important;
}

.dashboardCard .form-control label:active {
  border-color: none !important;
}

.dashboardCard .form-outline .form-control:focus~.form-notch .form-notch-leading {
  border-top: none;
  border-bottom: none;
  border-left: none;
  background: #F3F3F3;
}
.dashboardCard .form-outline .form-control:focus~.form-notch .form-notch-middle {
  border-bottom: none !important;
  border-color: none !important;

}

 .form-outline .form-control:focus~.form-notch .form-notch-trailing {
  border-color: none !important;
  border-bottom: none !important;
  border-right: none !important;
  border-top: none !important;

}

.purchaseDate {
  border-color: none !important;
  border-bottom: none !important;
  border-right: none !important;
  border-top: none !important;
}

.dashboardCard .form-outline .form-control~.form-notch div {
  border: none;
}

.info-edit-box .form-outline .form-control~.form-notch div {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #E1E1E1;
}

.dashboardCard .form-outline .form-control:focus~.form-label,
.dashboardCard .form-outline .select-input.form-control:focus~.form-label {
  color: #757575;
}

.payment-box {
  border-bottom: solid 1px #E1E1E1;
}

 .img-credit-payed {
  width: 67px;
  height: 67px;
}

.btnBlue {
  width: fit-content;
}

.dot1,
.dot2,
.dot3 {
  color: #757575;
  line-height: 50%;
}

.dot1 {
  font-size: 75px;
  margin-right: 10px;
}
.dot2 {
  font-size: 60px;
  margin-right: 10px;
}
.dot3 {
  font-size: 45px;
}

.spinnerContainer {
  margin-top: 30vh;
}

@media only screen and (max-width: 1199px) {
  .bgGradient {
    background: #f3f3f3;
    width: auto !important;
    height: auto !important;
  }

  .dashboardCard {
    padding: 50px 50px;
    max-width: 850px;
  }

  #invoicesScreen .dashboardCard {
    padding: 50px 30px !important;
  }


  .dashboardCard {
    padding: 50px !important;
  }
}

@media only screen and (max-width: 991px) {
  .purchaseHistory {
    height: fit-content;
  }

  .dashboardCard {
    border: none !important;
    padding: 50px 50px !important;
  }

  .spinnerContainer {
    margin-top: 15vh;
  }
}

@media only screen and (max-width: 767px) {
  .dashboardCard {
    padding: 40px 20px !important;
    background: none;
    box-shadow: none;
  }
}

@media only screen and (max-width: 575px) {
  .bgGradient {
    background: #f3f3f3;
  }

  .dashboardCard {
    padding: 40px 20px !important;
    background: none;
    box-shadow: none;
  } 
}
