.registrationFormBox {
  margin-top: 15vh;
  padding-bottom: 40px;
  max-width: 520px;
  animation: fadeInAnimation ease 1s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.registrationFormBox h2 {
  font-weight: bold;
  line-height: 140%;
}



.bold {
  font-weight: 600;
  font-size: 1.1rem;
}

.btnLight {
  background-color: #eeeeee;

}

.btnLight:hover {
  background-color: #dcdcdc;
}


@media screen and (max-width: 767px) {
  .registrationFormBox {
    margin-top: 0 !important;
    padding-top: 50px;
  }

  .registrationFormBox .pRegistration {
    font-weight: 400;
  }
}
