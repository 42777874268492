.btnYellow {
  background: #f5b640;
  border-radius: 6px;
  color: #1f1f1f !important;
  font-size: 1rem;
  line-height: 140%;
  font-weight: 700;
  text-transform: uppercase;
  padding: 12px 30px;
}

.btnYellow:hover {
  background-color: #f4a514 !important;
  border-color: none;
  color: #1f1f1f !important;
}

.btnYellow:focus {
  background-color: #f4a514 !important;
  color: #1f1f1f !important;
}

/* Hero Main*/
.heroMain {
  background-color: #c4e6f3;
  background-image: url('/src/assets/img/background-desktop.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 50px;
  overflow: hidden;
}


.heroMainText a {
  width: fit-content;
}

.imgBox {
  background-image: url('../../assets/img/star-3.svg');
  background-repeat: no-repeat;
  background-position: right 25% top 13%;
  background-size: 19px;
}

.heroMain .heroImg {
width: 100%;
margin-bottom: -3%;
margin-top: 3%;
}

.heroMain .heroMainText {
  padding-top: 9%;
  padding-left: 8%;
  background-image: url('../../assets/img/star-1.svg');
  background-repeat: no-repeat;
  background-position: left 18% top 15%;
  background-size: 19px, 23px;
  
}

.heroMain .btnYellow {
  margin-top: 45px !important;
}

.heroMain h1 {
  margin-right: -200px;
  line-height: 150%;
  color: #1f1f1f;
}

.infoHero {
  margin-top: 0 !important;
  background-image: url('../../assets/img/star-1.svg');
  background-repeat: no-repeat;
  background-position: right 34% top 5%;
}

.features {
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: 0px 11.061px 33.1829px rgba(0, 0, 0, 0.14);
  padding: 30px 10px 20px 10px;
}

.features p {
  color: #1f1f1f;
  padding: 0;
  margin: 0;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 1.25rem;
}

.pFeatures {
  font-size: 0.9rem;
}

/* Coming soon section*/
.comingSoon {
  background-color: #c4e6f3;
  background-image: url('/src/assets/img/background-desktop.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 70px;
  padding-right: 0 !important;
}

.soonBox {
  background-image: url('../../assets/img/soonBox.svg');
  background-repeat: no-repeat;
  font-weight: 700 !important;
  
}

.comingSoonImg {
  margin-right: 0;
  background-image: url('../../assets/img/star-1.svg');
  background-repeat: no-repeat;
  background-position: right 20% top 5%;
}

.comingSoonText {
  padding-top: 15%;
  padding-left: 40%;
  background-image: url('../../assets/img/star-1.svg');
  background-repeat: no-repeat;
  background-position: right 2% bottom 1%;
}

.comingSoonText p {
  margin-right: -200px;
}

.comingSoon .checkP {
  font-size: 1.1rem;
}

.comingSoon h1 {
  font-weight: 300 ;
  line-height: 150%;
  text-transform: uppercase;
}

.comingSoon .check {
  margin-right: 10px;
}

/* How it works Section */

.soFunction {
  padding: 120px 0;
}

.soFunction .kreduBox {
  background-image: url('../../assets/img/bgkredu.svg');
  background-repeat: no-repeat;
  font-weight: 700 !important;
}

.soFunction h1 {
  font-size: 2.4rem !important;
  font-weight: 300 !important;
  line-height: 150% !important;
  text-transform: uppercase;
}

.soFunction .num {
  font-size: 1.9rem;
  font-weight: 400;
  line-height: 140%;
  text-align: left;
}



.howImg {
  filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.34));
  border-radius: 7px;
}

/* Warum Kredu */
.warumKredu {
  margin: 0 auto;
  padding: 120px 0;
}

.warumKredu h1 {
  font-weight: 300 !important;
  line-height: 150% !important;
  text-transform: uppercase;
}

.warumKredu p {
  max-width: 280px;
  margin-left: auto;
  margin-right: auto;
}

.warumKredu .kreduBox {
  background-image: url('../../assets/img/kredubg.svg');
  background-repeat: no-repeat;
  font-weight: 700 !important;
  font-size: 2.6rem;
}

.voucher {
  width: 223px;
}

/* Vouchers Section */
.vouchersSection {
  padding: 120px 0;
}

.vouchersSection h1 {
  font-weight: 300 !important;
  line-height: 150% !important;
  text-transform: uppercase;
}

.vouchersSection .kreduBox {
  background-image: url('../../assets/img/productsbg.svg');
  background-repeat: no-repeat;
  font-weight: 700 !important;
  font-size: 2.6rem;
}


.vouchersSection .vouchers {
  margin-top: 80px;
  margin-bottom: 50px;
}

.vouchersSection img {
  width: 20%;
}

.info-list {
  padding-left: 25px !important;
}

/* FAQ */
.faq {
  padding: 120px 0;
}

.faq .accordion-button {
  font-weight: 400 !important;
  border-top: 1px solid #1f1f1f !important;
}

.faq .accordion-button:not(.collapsed) {
  color: #1f1f1f !important;
}
.faq .accordion-button:focus {
  color: #1f1f1f !important;
}

.faq h1 {
  font-weight: 300 !important;
  line-height: 150% !important;
  text-transform: uppercase;
}

.faq .fragenBox {
  background-image: url('../../assets/img/bgFragen.svg');
  background-repeat: no-repeat;
  font-weight: 700 !important;
  font-size: 2.6rem;
}

.faq .accordion-button::after {
  color: #074b89 !important;
}

.faq .accordion-header {
  font-weight: 400;
}

.faq .accordion-header:not(.collapsed) {
  color: #1f1f1f !important;
}
.faq .accordion-header:focus {
  color: #1f1f1f !important;
}

.faq .card {
  border-top: #b5b5b5 solid 1px;
  border-bottom: #b5b5b5 solid 1px;
  border-left: 0;
  border-right: 0;
}

.faq a {
  color: #1f1f1f;
  display: flex;
}

.faq .accordion-flush {
  background-color: transparent;
}

.faqItem {
  margin-bottom: 10px;
  background-color: #f3f3f3 !important;
}

.faqItem h2 {
  line-height: 100%;
  color: #074b89 !important;
}

.faq i {
  justify-items: left;
  margin-left: auto;
  color: #19a8b4;
}

.faq .card-body {
  padding: 10px 18px 18px 0;
}

.faq .card-body a {
  color: #19a8b4;
}

/* infoKredu section */
.infoKredu {
  padding: 120px 0;
}

.infoKredu h1 {
  font-weight: 300;
  line-height: 150% !important;
  text-transform: uppercase;
}

.infoKredu .kreduBox {
  background-image: url('../../assets/img/bgkredu.svg');
  background-repeat: no-repeat;
  font-weight: 700 !important;
  font-size: 2.6rem;
}

.infoKredu .numbers {
  line-height: 150%;
}

.infoKredu .line {
  border-top: solid 1px #e1e1e1;
}

.infoKredu p {
  font-size: 1.25rem;
}

.infoKredu .logoImg {
  width: 15%;
}

/* Resposivness */

@media only screen and (min-width: 1800px) {
  .heroMain .heroMainText {
    background-position: left 21% top 16%;
    padding-top: 9%;
  }

  .heroMain h1 {
    font-size: 3rem !important;
    line-height: 140%;
    margin-right: -200px;
  }
  .heroMain p {
    font-size: 1.1rem !important;
  }

  .infoHero img {
    min-width: 100px;
  }

  .comingSoon .checkP {
    font-size: 1.3rem;
  }
}
@media only screen and (min-width: 1920px) {
  .heroMain .heroImg {
  width: 100%; /* Make the image take the full width of its container */
  margin-bottom: 0;
  margin-top: 3%;
}
  .heroMain .heroMainText {
    background-position: left 22% top 17%;
    background-size: 22px, 25px;
    padding-top: 9%;
    padding-left: 9%;
  }

  .imgBox {
    background-size: 22px;
  }

  .btnYellow {
    font-size: 1.1rem !important;
  }

  .heroMain h1 {
    font-size: 3.4rem !important;
    line-height: 140%;
  }

  .heroMain p {
    font-size: 1.3rem !important;
  }

  .comingSoonText {
    padding-top: 20%;
    padding-left: 50%;
  }
}
@media only screen and (min-width: 2300px) {
  .heroMain .heroImg {
  width: 100%; /* Make the image take the full width of its container */
  margin-bottom: 0;
  margin-top: 3%;
}
  .heroMain .heroMainText {
    background-position: left 21% top 13%;
    padding-left: 8%;
    padding-top: 7%;
  }

  .heroMain .btnYellow {
    font-size: 1.3rem !important;
  }

  .heroMain h1 {
    font-size: 3.8rem !important;
  }

  .heroMain p {
    font-size: 1.6rem !important;
  }

  .infoHero {
    margin-top: -0.8rem !important;
  }

  .infoHero img {
    min-width: 180px;
  }
}
@media only screen and (min-width: 2500px) {
  .heroMain .heroMainText {
    background-position: left 23% top 13%;
    background-size: 24px, 28px;
    padding-left: 8%;
    padding-top: 7%;
  }

  .heroMain .btnYellow {
    font-size: 1.6rem !important;
  }

  .heroMain h1 {
    font-size: 4.2rem !important;
  }

  .comingSoonText {
    padding-left: 70%;
  }
}

@media only screen and (min-width: 2800px) {
  .heroMain .heroImg {
  margin-top: 3%;
}
  .heroMain .heroMainText {
    background-position: left 25% top 20%;
    background-size: 30px;
    padding-left: 10% !important;
    padding-top: 8%;
  }

  .heroMain .btnYellow {
    font-size: 1.8rem !important;
    margin-top: 60px !important;
  }

  .heroMain h1 {
    font-size: 4.4rem !important;
  }
}

@media only screen and (min-width: 3280px) {
  .heroMain .heroImg {
  margin-top: 3%;
}
  .heroMain .heroMainText {
    background-position: left 28% top 18%;
    background-size: 32px, 40px;
    padding-left: 12% !important;
    padding-top: 9%;
  }

  .imgBox {
    background-size: 32px;
  }

  .heroMain .btnYellow {
    font-size: 1.6rem !important;
    margin-top: 70px !important;
  }

  .heroMain h1 {
    font-size: 4.5rem !important;
  }

  .heroInfo {
    margin-top: -350px;
  }

  .heroInfo img {
    min-width: 250px;
  }

  .comingSoonImg img {
    width: 1500px;
  }

  .comingSoonText {
    padding-top: 18%;
    padding-left: 75%;
  }

  .comingSoonText p {
    font-size: 2em;
  }

  .kreduBox {
    font-size: 43px !important;
  }
  .soonBox {
    font-size: 43px !important;
  }
}

@media only screen and (min-width: 3690px) {
  .heroMain .heroImg {
  width: 100%; /* Make the image take the full width of its container */
  height: auto;
  margin-top: 3%;
}
  .heroMain .heroMainText {
    background-position: left 30% top 22%;
    background-size: 35px, 43px;
    padding-left: 13% !important;
  }

  .imgBox {
    background-size: 35px;
  }

  .heroMain .btnYellow {
    font-size: 1.7rem !important;
    margin-top: 80px !important;
  }

  .heroMain h1 {
    font-size: 4.6rem !important;
  }

  .infoHero {
    margin-top: -360px;
    background-size: 35px;
  }

  .infoHero img {
    min-width: 260px;
  }

  .kreduBox {
    font-size: 43px !important;
  }
  .soonBox {
    font-size: 43px !important;
  }

  .comingSoonText p {
    font-size: 3em;
  }
}

@media only screen and (max-width: 1560px) {
  .heroMain .heroMainText {
    background-position: left 18% top 12%;
    padding-top: 8%;
  }

  .heroMain h1 {
    font-size: 2.6rem !important;
  }

  .heroMain .btnYellow {
    margin-top: 45px !important;
  }

  .infoHero {
    background-position: right 30% top 5%;
  }

  .comingSoonText {
    padding-top: 18%;
    padding-left: 50%;
  }
}

@media only screen and (max-width: 1399px) {

  .heroMain h1 {
    font-size: 2.3rem !important;
  }

  .infoHero {
    margin-top: 0 !important;
    background-position: right 25% top 5%;
  }

  .heroMain .heroMainText {
    background-position: left 17% top 10%;
    padding-top: 8%;
    padding-left: 5%;
  }

  .comingSoonText {
    padding-top: 18%;
    padding-left: 40%;
  }
}


@media only screen and (max-width: 1199px) {

  .heroMain .heroMainText {
    background-position: left 18% top 9%;
    padding-top: 7%;
    padding-left: 6%;
  }

  .heroMain .btnYellow {
    margin-top: 45px !important;
  }
  
  .infoHero {
    margin-top: -100px !important;
    background-position: right 25% top 5%;
  }

  .infoHero img {
    width: 180px;
    background: none;
  }

  .infoKredu .logoImg {
    width: 18%;
  }

  .comingSoonText {
    padding-top: 18%;
    padding-left: 30%;
  }

  .comingSoon .checkP {
    font-size: 1rem;
  }
}

@media screen and (max-width: 991px) {
  .heroMain .heroImg {
    padding-left: 0;
    margin-top: -40px;
  }

  .imgBox {
    background-image: url('../../assets/img/star-3.svg'),
      url('../../assets/img/star-3.svg');
    background-repeat: no-repeat, no-repeat;
    background-position: left 10% top 10%, right 12% top 68%;
    background-size: 19px, 19px;
  }

  .btnYellow {
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .heroMain .heroMainText {
    padding-top: 0 !important;
    margin-top: -190px;
    padding-left: 90px !important;
    background-image: none;
  }

  .heroMain h1 {
    font-size: 2.4rem !important;
    margin-right: 0 !important;
  }

  .infoHero {
    margin-top: 20px !important;
    background: none;
  }

  .cardsSection .cardBox {
    padding-bottom: 20px !important;
  }

  .warumKredu h2 {
    font-size: 1.9rem !important;
    line-height: 120% !important;
  }

  .comingSoonImg img {
    width: 100% !important;
  }

  .comingSoonImg {
    background-image: url('../../assets/img/star-1.svg'), url('../../assets/img/star-1.svg');
    background-repeat: no-repeat, no-repeat;
    background-position: left 5% top 15%, right 10% bottom 25%;
  }

  .comingSoonText {
    margin-top: -270px;
    padding-left: 10px;
    padding-top: 10ox;
    margin-bottom: 40px;
    background: none;
  }

  .comingSoonText p {
    margin-right: 0;
  }

  
}

@media screen and (max-width: 767px) {
  .heroMain .heroImg {
    margin-top: -40px;
    margin-bottom: 15px !important;
  }

  .heroMain .heroMainText {
    padding-top: 0 !important;
    margin-top: -160px;
    padding-left: 40px !important;
  }

  .heroMain h1 {
    font-size: 2.2rem !important;
  }

  .btnYellow {
    margin-top: 10px;
  }

  .infoHero img {
    width: 100px;
  }

  .warumKredu h2 {
    font-size: 2.4rem !important;
    margin-bottom: 32px !important;
  }

  .comingSoonText {
    margin-top: -180px;
  }
  
  .vouchersSection {
    padding-bottom: 110px;
    padding-top: 110px;
  }

  .vouchersSection h1 {
    font-size: 2rem;
  }
}

/* Mobile Phones*/
@media only screen and (max-width: 575px) {
  .heroMain {
    background: url('/src/assets/img/background-mobile.png') no-repeat;
    background-size: cover;
    padding-top: 50px;
  }

  .heroMain .btnYellow {
    width: 100%;
    margin-top: 0px !important;
  }

  .heroMainText a {
    width: 100%;
  }

  .heroMain .heroImg {
    margin-left: 0 !important;
    margin-top: -10px !important;
    margin-right: 0 !important;
    padding: 0 !important;
  }

  .heroMain .heroMainText {
    padding-top: 20px !important;
    margin-top: -125px;
    padding-left: 0 !important;
  }

  .heroMain h1 {
    font-size: 1.8rem !important;
    padding-left: 20px;
    padding-right: 10px;
  }

  .heroMain .checkList {
    padding-left: 20px;
    padding-right: 10px;
    size: 0.95rem;
  }

  .heroInfo img {
    width: 19%;
  }

  .checkList {
    font-size: 1rem;
  }

  .kreduBox {
    font-size: 1.9rem;
  }

  .features p {
    font-size: 1.1rem;
  }

  .comingSoonText {
    margin-top: -130px;
    padding-left: 10px;
    margin-bottom: 40px;
  }

  .voucher {
    width: 106px;
  }

  .soFunction {
    padding: 50px 0;
  }

  .soFunction h1 {
    font-size: 1.9rem !important;
  }

  .soFunction .num {
    font-size: 1.5rem;
  }

  .soFunction .howWidth {
    padding: 0 70px;
  }

  .soFunction img {
    width: 100%;
  }

  .warumKredu {
    padding: 50px 0;
  }


  .warumKredu h1 {
    font-size: 1.9rem !important;
  }

  .vouchersSection {
    padding: 50px 0;
  }

  .vouchersSection h1 {
    font-size: 1.9rem !important;
  }

  .vouchersSection .vouchers {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .vouchersSection img {
    width: 50%;
  }

  .faq {
    padding: 50px 0;
  }

  .infoKredu {
    padding: 50px 0 30px 0;
  }

  .infoKredu .logoImg {
    width: 20%;
  }

  .vendor-info h2 {
    font-size: 1.87rem !important;
  }
}

@media only screen and (max-width: 495px) {
  .soFunction .howWidth {
    padding: 0 60px;
  }

  .faq h3 {
    font-size: 1.1rem !important;
  }
}
@media only screen and (max-width: 420px) {
  .heroMain h1 {
    font-size: 1.5rem !important;
    padding-left: 10px;
    padding-right: 10px;
  }

  .heroMain .checkList {
    padding-left: 10px;
    padding-right: 10px;
    size: 0.95rem;
  }
  .soFunction .howWidth {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 400px) {
  .soFunction .howWidth {
    padding: 0 5px;
  }
}

/* Very small device */
@media only screen and (max-width: 338px) {
  .btnYellow {
    font-size: 0.87rem;
  }

  .box-width {
    padding: 0 10px;
  }
}

.christmasModal {
  background-color: #ffffff; /* White background */
  border: 3px solid #dc3545; /* Dark red border */
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.christmasModalHeader {
  border-bottom: 3px solid #20c997;
  color: #20c997; 
  padding: 15px;
  border-bottom: 3px solid #20c997;
}

.christmasModalBody {
  background-color: #ffffff; /* White background */
  color: #000000; /* Black text */
  padding: 20px;
  font-family: 'Georgia', serif; /* Cozy font */
}

.christmasModalFooter {
  color: #ffffff; /* White text */
  padding: 10px;
  border-top: 3px solid #20c997;
}

.christmasModalBody p {
  margin: 0;
  padding: 0;
  line-height: 1.5;
}

.christmasModalBody .highlight {
  color: #20c997; /* Green text for highlights */
  font-weight: bold;
}

.christmasModalFooter .btn-close-green {
  background-color: #20c997; /* Custom color */
  border: none;
  color: #ffffff; /* White text */
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.christmasModalFooter .btn-close-custom:hover {
  background-color: #1aa179; /* Darker shade for hover */
}