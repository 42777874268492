.navbar {
  height: 55px;
  margin-bottom: 55px;
  box-shadow: none;
}

.badge {
  background-color: #074B89;
  position: absolute;
  font-size: .6rem;
  line-height: 150%;
  font-weight: 600;
  margin-top: -0.1rem;
  margin-left: -0.5rem;
  padding: 0.1em 0.57em;
  border-radius: 50rem;
  color: #fff;
}

.btnBack {
  background: none;
  border: none;
  outline: none;
  transition: none;
  box-shadow: none;
}

.btnBack:hover {
  background-color: #ffffff !important;
  border-color: #ffffff;
}

.btnBack:focus {
  background-color: #ffffff !important;
  border-color: #ffffff;
}

.btnBack:not(:disabled):not(.disabled):active {
  background: #ffffff;
  border-color: #ffffff;
}

.cartImg {
  height: 25px;
}

.cartP {
  font-size: 0.9rem;
}