.fontBlue {
  color: #074B89 !important;
  font-size: 1.25rem;
  font-weight: 500;
}

.btnBlue {
  background-color: #074B89;
}

.btnBlue {
  background: #ffffff;
  border: 2px solid #074b89;
  color: #074b89 !important;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: none;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 9px 20px;
}

.btnBlue:hover {
  background-color: #ffffff !important;
  border: 2px solid #074b89;
  color: #074b89;
}
.btnBlue:focus {
  background-color: #ffffff !important;
  border: 2px solid #074b89;
  color: #074b89;
}

@media only screen and (min-width: 1800px) {
  .fontBlue {
    font-size: 1.4rem;
}
}