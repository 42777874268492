@media only screen and (min-width: 1800px) {
  #heroMain .hero-main-text {
    padding-top: 120px;
    padding-left: 200px !important;
  }

  #heroMain h1 {
    font-size: 3rem !important;
    line-height: 140%;
  }

  #heroMain h3 {
    font-size: 1.7rem !important;
    line-height: 140%;
  }
  #heroMain p {
    font-size: 1.1rem !important;
  }
}
@media only screen and (min-width: 1980px) {
  #heroMain .hero-main-text {
    padding-top: 120px;
    padding-left: 280px !important;
  }

  #heroMain h1 {
    font-size: 3.2rem !important;
    line-height: 140%;
  }

  #heroMain h3 {
    font-size: 1.8rem !important;
    line-height: 140%;
  }
  #heroMain p {
    font-size: 1.2rem !important;
  }

  #heroMain .hero-img {
    margin-left: -100px !important;
  }
}
@media only screen and (min-width: 2300px) {
  #heroMain .hero-main-text {
    padding-top: 150px !important;
    padding-left: 280px !important;
  }

  #heroMain h1 {
    font-size: 3.7rem !important;
  }

  #heroMain h3 {
    font-size: 2rem !important;
    line-height: 140%;
  }
  #heroMain p {
    font-size: 1.5rem !important;
  }

  #heroMain .hero-img {
    margin-left: -220px !important;
  }
}
@media only screen and (min-width: 2500px) {
  #heroMain .hero-main-text {
    padding-top: 200px !important;
    padding-left: 350px !important;
  }

  #heroMain h1 {
    font-size: 4.2rem !important;
  }

  #heroMain h3 {
    font-size: 2.2rem !important;
  }
  #heroMain p {
    font-size: 1.7rem !important;
  }

  #heroMain .hero-img {
    margin-left: -220px !important;
  }
}

@media only screen and (min-width: 3280px) {
  * {
    font-size: 18px;
  }

  .btn-yellow {
    font-size: 0.8rem;
  }

  #heroMain .hero-main-text {
    padding-top: 230px !important;
    padding-left: 550px !important;
  }

  #heroMain h1 {
    font-size: 5rem !important;
  }

  #heroMain h3 {
    font-size: 2.3rem !important;
  }
  #heroMain p {
    font-size: 1.8rem !important;
  }

  #heroMain .hero-img {
    margin-left: -280px !important;
  }

  .cardsSection .btn-yellow,
  #favoriteProducts .btn-yellow {
    font-size: 0.7rem !important;
  }
}

@media only screen and (max-width: 1560px) {
  #heroMain .hero-main-text {
    padding-top: 90px !important;
    padding-left: 130px !important;
  }
}

@media only screen and (max-width: 1399px) {
  #heroMain .hero-img {
    margin-left: -60px !important;
  }

  #heroMain .hero-main-text {
    padding-top: 50px !important;
    padding-left: 90px !important;
  }

  #heroMain h1 {
    font-size: 2.3rem !important;
  }

  .cardsSection .btn-yellow,
  #favoriteProducts .btn-yellow {
    padding: 5px 5px !important;
    font-size: 0.7rem !important;
    line-height: 1.3rem;
    margin-left: 15px;
  }
}

@media only screen and (max-width: 1232px) {
  #warumKredu {
    margin: 0 !important;
  }

  #warumKredu .img-warum-kredu {
    margin-top: -80px !important;
  }
}

@media only screen and (max-width: 1199px) {
  .navbar-collapse {
    background: #ffffff;
    width: 100vw;
    min-height: 100vh;
    text-align: center;
  }

  #navbarMain li,
  #navbarDashboard li,
  #navbarDashboardOnly li,
  #navbarHomepage li {
    border-bottom: 1px solid #c6c6c6;
    padding: 12px 0;
    width: 100%;
  }

  .side-progress-bar {
    margin-right: 50px !important;
  }

  #purchaseHistory .bg-gradient,
  #navbarDashboardOnly .bg-gradient {
    background-color: #f3f3f3;
    width: auto !important;
    height: auto !important;
  }

  #purchaseHistory .dashboard-card {
    padding: 50px !important;
  }

  #invoicesScreen .dashboard-card {
    padding: 50px 30px !important;
  }

  #heroMain .hero-main-text {
    padding-top: 50px !important;
    padding-left: 50px !important;
  }

  #heroMain .hero-img {
    margin-left: -20px !important;
  }

  #heroMain h1 {
    font-size: 2.1rem !important;
  }

  .cardsSection .btn-yellow,
  #favoriteProducts .btn-yellow {
    padding: 5px 10px !important;
    font-size: 1rem !important;
  }
}

@media screen and (max-width: 991px) {
  .side-progress-bar {
    margin-right: 50px !important;
  }

  .registration-form-box {
    min-width: 0 !important;
  }
  .registration-form-box-2 {
    min-width: 0 !important;
  }

  #purchaseHistory,
  #navbarDashboardOnly {
    height: fit-content;
  }

  #purchaseHistory .dashboard-card {
    background-color: #f3f3f3 !important;
    background-clip: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  #heroMain .hero-img {
    margin-top: -120px !important;
    margin-left: -40px;
  }

  .cardsSection .card-box {
    padding-bottom: 20px !important;
  }

  #warumKredu .box-3,
  #warumKredu .box-4 {
    margin-top: -270px !important;
  }

  #warumKredu p {
    padding: 0 16px !important;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }

  #warumKredu h2 {
    font-size: 1.9rem !important;
    line-height: 120% !important;
  }

  #warumKredu h4 {
    font-size: 1.25rem !important;
    line-height: 1.89rem !important;
  }

  #soFunctioniert .card-story-img {
    margin-top: -20px !important;
  }

  #mainFooter p,
  #mainFooter a {
    font-size: 0.87rem !important;
  }
}

@media screen and (max-width: 767px) {
  .row {
    --mdb-gutter-x: 0 !important;
  }

  .registration-form-box,
  .registration-form-box-2 {
    margin-top: 0 !important;
  }

  .registration-form-box .p-registration,
  .registration-form-box-2 .p-registration {
    font-weight: 400;
  }

  .registration-form-box .btn-yellow,
  .registration-form-box-2 .btn-yellow {
    width: 100%;
  }

  .btn-registration-mail {
    margin-top: 60vh;
  }

  .form-box {
    max-width: 100%;
  }

  .box-width {
    padding: 0 20px;
  }

  .container-registration {
    height: 100%;
    padding-top: 35%;
  }

  .registration-form-box .otp-numbers {
    width: 60% !important;
  }

  

  #dashboardNavMobile a {
    color: #1f1f1f;
  }

  #invoicesScreen .dashboard-card {
    padding: 10px !important;
    background-color: #f3f3f3 !important;
    background-clip: none !important;
    border: none !important;
    box-shadow: none !important;
    margin-top: 30px;
  }

  #heroMain .hero-main-text {
    padding-top: 40px !important;
    padding-left: 25px !important;
  }

  #heroMain .hero-img {
    margin-top: -80px !important;
    margin-left: 0 !important;
  }

  #warumKredu h2 {
    font-size: 2.4rem !important;
    margin-bottom: 32px !important;
  }

  #warumKredu p {
    padding: 0 32px !important;
  }

  #warumKredu .warum-box {
    margin: 0 auto 40px auto !important;
  }

  #warumKredu .img-box {
    padding: 0 !important;
    margin: 0 !important;
    margin-top: 20px !important;
  }

  #warumKredu .img-warum-kredu {
    height: 100% !important;
    width: 100% !important;
    margin: 0 !important;
  }

  #warumKredu .box-3 {
    margin-top: -64px !important;
  }

  #warumKredu .box-4 {
    margin-bottom: 90px !important;
  }

  #mainFooter .main-footer-box {
    padding: 24px !important;
  }
}

/* Mobile Phones*/
@media only screen and (max-width: 575px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.37rem;
  }

  .p-smaller {
    size: 0.8rem !important;
  }

  .container-heigh {
    margin-top: 8vh;
  }

  

  #shoppingCartComponent .btn-yellow {
    font-size: 0.95rem;
    padding: 15px 6px;
  }

  .shopping-card-total {
    background: #ffffff;
  }

  .shopping-card input {
    font-size: 2rem !important;
    padding-left: 10px;
  }

  .shopping-card p,
  .shopping-card-2 p {
    font-size: 0.85rem;
  }

  .shopping-card-total-loggedIn .buttons-container {
    width: 100%;
  }

  .shopping-card-total-loggedIn .btn-yellow,
  .shopping-card-total-loggedIn .btn-klarna {
    width: 100%;
  }

  .registration-form-box .otp-numbers {
    width: 60% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .navbar {
    height: 60px;
  }

  #navbarMain .kredu-logo,
  #navbarDashboard .kredu-logo {
    width: 80px !important;
    margin-left: 4%;
  }

  .btn-position-bottom {
    position: fixed;
    bottom: 0px;
    margin: 0 auto;
    width: 95%;
  }

  .additional-documents-screen h3 {
    font-size: 1.4rem;
  }

  .documents-verification-details .btn-secondary-blue {
    font-size: 0.95rem;
  }

  #purchaseHistory .dashboard-card {
    padding: 15px !important;
  }

  #heroMain .hero-img {
    margin-left: 0 !important;
    margin-top: 0px !important;
    margin-right: 0 !important;
    padding: 0 !important;
  }

  #heroMain .hero-main-text {
    padding-top: 20px !important;
    padding-left: 15px !important;
  }

  #heroMain h1 {
    font-size: 2rem !important;
  }
  #heroMain h3 {
    font-size: 1.25rem !important;
  }


  #warumKredu .img-warum-kredu {
    margin-bottom: 20px !important;
  }

  #warumKredu h2 {
    font-size: 1.5rem !important;
  }

  #soFunctioniert h3 {
    font-size: 1.25rem !important;
  }

  #mainFooter {
    margin-top: 16px !important;
    padding: 16px 0 !important;
  }

  #mainFooter .social-media {
    order: 1 !important;
    justify-content: center !important;
  }

  #mainFooter .copyright-box {
    order: 2 !important;
    text-align: center !important;
  }

  #mainFooter .logo-box {
    order: 3 !important;
  }
}

@media only screen and (max-width: 495px) {
  .registration-form-box .otp-numbers {
    width: 80% !important;
  }

  .card-box img {
    width: 100%;
  }

  
}

/* Very small device */
@media only screen and (max-width: 338px) {
  .btn-yellow {
    font-size: 0.87rem;
  }

  #shoppingCartComponent .btn-yellow {
    font-size: 0.9rem;
    padding: 13px 6px;
  }
  .shopping-card p,
  .shopping-card-2 p {
    font-size: 0.75rem;
  }

  .shopping-card-total .btn-yellow {
    padding: 12px 30px;
  }

  .box-width {
    padding: 0 10px;
  }

  .registration-form-box .otp-numbers {
    width: 100% !important;
  }

  .documents-verification-details .btn-secondary-blue {
    font-size: 0.85rem;
  }

  .cardsSection .btn-yellow,
  .favoriteProducts .btn-yellow {
    padding: 5px 10px !important;
    font-size: 0.75rem !important;
  }
}
