.voucherImg {
  width: 290px !important;
}

.loading {
  margin-top: 20vh;
  margin-bottom: 15vh;
}

.vendorInfo h1,
.vendorInfo h2 {
  font-size: 1.3rem;
}

.vendorName {
  font-size: 1.8rem !important;
}

@media only screen and (min-width: 3280px) {
.cards-section .btn-yellow {
    font-size: 0.7rem !important;
  }
}

@media only screen and (max-width: 575px) {
  .vendorInfo h1,
  .vendorInfo h2 {
  font-size: 1.2rem;
}

.vendorName {
  font-size: 1.6rem !important;
}

  .loading {
    margin-top: 10vh;
    margin-bottom: 15vh;
  }
}