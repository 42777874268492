.shoppingCart {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

.shoppingCart img {
  width: 130px;
}

.shoppingCart p {
  color: #1f1f1f;
}

.shoppingCart input {
  width: 60px;
  border: none;
  text-align: center;
  font-size: 2.6rem;
  font-weight: bold;
  color: #1f1f1f;
  padding-bottom: 0;
}

.shoppingCart .addBtn {
  background: #F3F3F3;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  border: none;
  width: 30px;
  
}

.shoppingCart h3 {
  font-size: 33px;
  line-height: 150%;
  font-size: 2rem;
  letter-spacing: 0ch;
}

.shoppingCart-2 {
  border: none;
  border-radius: 6px;
}

.shoppingCart-2 .fa-check-circle {
  font-size: 1.35rem;
}



.shoppingCart-total .btn-yellow {
  padding: 12px 32px;
}

.paymentsBox {
  width: 50%;
}

.borderText {
  border: 1px solid #dbd9d9;
  border-radius: 5px;
  font-size: 0.9rem;
}

.spinnerContainer {
  margin-top: 40vh;
}

.smallInfo {
  font-size: 0.68rem;
}

.vatBox {
  font-size: 0.9rem;
}

.infoBox {
  margin-bottom: 50px;
}

.fontBlue {
  color: #074B89;
  
}

@media only screen and (max-width: 1120px) {
  
  .paymentsBox {
    width: 60%;
  }
}
@media only screen and (max-width: 991px) {
  
  .paymentsBox {
    width: 100%;
  }

  .spinnerContainer {
    margin-top: 20vh;
  }

  .small {
    font-size: 0.85rem;
  }

  .infoBox {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 575px) {
  
  .container-heigh {
    margin-top: 8vh;
  }

  #shoppingCartComponent .btn-yellow {
    font-size: 0.95rem;
    padding: 15px 6px;
  }

  .shoppingCart-total {
    background: #ffffff;
  }

  .shoppingCart input {
    font-size: 2rem !important;
    padding-left: 10px;
  }

  .shoppingCart p,
  .shoppingCart-2 p {
    font-size: 0.85rem;
  }

  .shoppingCart-total-loggedIn .buttons-container {
    width: 100%;
  }

  .shoppingCart-total-loggedIn .btn-yellow,
  .shoppingCart-total-loggedIn .btn-klarna {
    width: 100%;
  }

  .registration-form-box .otp-numbers {
    width: 60% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  #shoppingCartComponent .btn-yellow {
    font-size: 0.95rem;
    padding: 15px 6px;
  }

  .shoppingCart-total {
    background: #ffffff;
  }

  .shoppingCart input {
    font-size: 2rem !important;
    padding-left: 10px;
  }

  .shoppingCart p {
    font-size: 0.85rem;
  }

  .shoppingCart-total-loggedIn .buttons-container {
    width: 100%;
  }
}

@media only screen and (max-width: 495px) {
  .registration-form-box .otp-numbers {
    width: 80% !important;
  }

  .card-box img {
    width: 100%;
  }
}

/* Very small device */
@media only screen and (max-width: 338px) {
  .shoppingCart p,
  .shoppingCart-2 p {
    font-size: 0.75rem;
  }

  .shoppingCart-total .btn-yellow {
    padding: 12px 30px;
  }

  .box-width {
    padding: 0 10px;
  }

  .registration-form-box .otp-numbers {
    width: 100% !important;
  }

  .documents-verification-details .btn-secondary-blue {
    font-size: 0.85rem;
  }

  .cardsSection .btn-yellow,
  .favoriteProducts .btn-yellow {
    padding: 5px 10px !important;
    font-size: 0.75rem !important;
  }
}


@media only screen and (max-width: 338px) {
  #shoppingCartComponent .btn-yellow {
    font-size: 0.9rem;
    padding: 13px 6px;
  }
  .shoppingCart p,
  .shoppingCart-2 p {
    font-size: 0.75rem;
  }

  .shoppingCart-total .btn-yellow {
    padding: 12px 30px;
  }
}


.klarna {
  background: #FFB3C7;
  width: fit-content;
  height: fit-content;
  padding: 2px 6px;
  border-radius: 6px;
}
.paypal {
  width: fit-content;
  padding-right: 4px;
  padding-left: 4px;
  border-radius: 6px;
}

.checkBoxes input~.form-check-label {
  font-weight: bold !important;
}

.boxBorder {
  border-bottom: 2px solid #f3f3f3;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.boxDown {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.badgeText {
  font-size: 0.8rem;
}

.infoColor {
  background: rgb(35 179 225) !important;
}

.tooltip {
  font-size: 0.7rem !important;
  text-align: start !important;
}


.checkBoxes .form-check-input:checked {
  background-color: #074B89 !important;
  border-color: #074B89 !important;
}



