.imprint h1 {
  padding: 90px 0 50px 0;
}

.imprint hr {
  border-top: 1px solid #1F1F1F;
}

.link {
  text-decoration: underline;
}