.marginTop {
    margin-top: 20vh;
  }
  
  .errorBox {
    max-width: 500px;
  }
  
  .link {
    text-decoration: underline;
    color: #074b89;
    border: none;
    background: none;
    font-size: 1.1rem;
  }
  
  @media only screen and (max-width: 767px) {
    .marginTop {
      margin-top: 3vh;
    }
  }