.registrationFormBox {
  margin-top: 30vh;
  padding-bottom: 40px;
  min-width: 400px;
  animation: fadeInAnimation ease 1s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}


.registrationFormBox h2 {
  font-weight: bold;
  line-height: 140%;
}

.registrationFormBox p {
  font-weight: bold;
  padding-right: 10px;
}

.registrationFormBox .formControl {
  background: #fff;
  padding: 8px;
  color: #1f1f1f !important;
  margin-bottom: 1.5rem;
}

.fontBlue {
  color: #074B89 !important;
  text-decoration: underline;
}


.registrationFormBox .input-group>.formControl:focus {
  transition: all .2s linear;
  border-color: #074B89 !important;
  outline: 0;
  box-shadow: inset 0 0 0 1px #074B89 !important;
}

.registrationFormBox .select-input.formControl[readonly]:not([disabled]) {
  background-color: #fff;
}

.registrationFormBox .form-outline .formControl~.form-notch div {
  border: none !important;
}

#purchaseHistory .form-outline .form-control~.form-notch div,
#invoicesScreen .form-outline .form-control~.form-notch div,
#registration .form-outline .form-control~.form-notch div,
#cart .form-outline .form-control~.form-notch div {
  border: none;
}


.registrationFormBox .form-outline {
  border: none !important;
}

.registrationFormBox .select-input-label-active {
  color: #074B89 !important;
}

.registrationFormBox .form-outline .formControl:focus~.form-label {
  color: #074B89 !important;
}

.select-input.focused~.select-label {
  color: #074B89 !important;
}


.formControl label:active {
  border-color: #074B89;
  
}

.form-outline:focus {
  background: #fff;
}
.form-outline:hover {
  background: #fff;  
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.form-outline .formControl:focus~.form-notch .form-notch-leading {
  border-top: .125rem solid #074B89;
  border-bottom: .125rem solid #074B89;
  border-left: .125rem solid #074B89;
  background: #fff;
}
.form-outline .formControl:focus~.form-notch .form-notch-middle {
  border-bottom: .125rem solid;
  border-color: #155ea2;
  
}

.form-outline .formControl:focus~.form-notch .form-notch-trailing {
  border-color: currentcolor currentcolor currentcolor #074B89;
  border-bottom: .125rem solid #074B89;
  border-right: .125rem solid #074B89;
  border-top: .125rem solid #074B89;
  
}

.form-outline .formControl:focus~.form-label, 
.form-outline .select-input.formControl:focus~.form-label {
  color: #074B89;
}		

.select-input-label-active {
  color: #074B89 !important;
  border-color: #074B89 !important;
}

.select-input:active {
  color: #074B89 !important;
  border-color: #074B89 !important;
}

.select-input-label:focus {
  color: #074B89 !important;
  border-color: #074B89 !important;
}


.form-check-input[type=checkbox]:checked {
  background-image: none;
  background-color: #074B89;
}

.form-check-input[type=checkbox]:focus{
  background-image: none;
  background-color: #074B89;
}

.formControl:focus {
  color: #074B89 !important;
  border-color: #074B89 !important;
  box-shadow: inset 0px 0px 0px 1px #074B89 ;
}

.formControl .form-label:active {
  color: #074B89 !important;
  border-color: #074B89 !important;
}

.formControl .form-label:focus {
  color: #074B89 !important;
  border-color: #074B89 !important;
}


.form-check-input[type=checkbox] {
  border-radius: 100px;
  margin-top: .19em;
  margin-right: 8px;
}

.additional-documents .input-group-text {
  border: none;

}

.document-row {
  border-bottom: solid 1px #E1E1E1;
}

.document-row p {
  font-size: 0.87rem;
  font-weight: normal;
  margin-bottom: 0 !important;
}


@media screen and (max-width: 991px) {
  .registrationFormBox {
    min-width: 0 !important;
  }
  .registrationFormBox-2 {
    min-width: 0 !important;
  }
}

@media screen and (max-width: 767px) {
  .registrationFormBox {
    margin-top: 0 !important;
    padding-top: 50px;
  }

  .registrationFormBox .pRegistration {
    font-weight: 400;
  }

  .registrationFormBox .btnYellow {
    width: 100% !important;
  }
}

@media only screen and (max-width: 575px) {
.registrationFormBox .formControl {
  background: #fff;
  padding: 8px;
  color: #1f1f1f !important;
  
  border: none !important;
}
}