.fontBlue {
  color: #074B89 !important;
  text-decoration: underline;
}

.loading {
  padding-top: 20vh;
}

.small {
  font-size: .95rem;
  font-weight: 500;
}

@media screen and (max-width: 767px) {
  .loading {
    padding-top: 9vh;
  }
}